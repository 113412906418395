/*
This plugin creates the doctor search results list button and launches the print.html window.

USAGE: The following defaults are applied, but can be overwritten if necessary

$(".DrResults").searchIdPrintResults({
	topLink: '#DrResults .SortContainer',			//The location to put the top button (uses jQuery before function).
	bottomLink: '#DrResults',				//The location to put the bottom button (uses jQuery append function)
	buttonName: 'Print Doctor Search Results List',		//The name to appear on the button
	windowHeight: 800,					//The height of the print.html window
	windowWidth: 800,					//The width of the print.html window
	windowScrollbar: 'yes'					//Should the window have scrollbars?
});

*/

(function($) {
	$.fn.searchIdPrintResults = function(options) {
		
		$.fn.searchIdPrintResults.defaults = {
			topLink: '.DrList',		
			bottomLink: '.DrList',					
			buttonName: 'Print Doctor Search Results List',		
			windowHeight: 800,									
			windowWidth: 800,									
			windowScrollbar: 'yes'								
		};
		var options = $.extend({}, $.fn.searchIdPrintResults.defaults, options);

		$(options.topLink).before('<p><a href="/html/print.html" class="PrintResults Right">' + options.buttonName + '</a></p>');
		$(options.bottomLink).append('<p><a href="/html/print.html" class="PrintResults Button">'+ options.buttonName +'</a></p>');
		
		// Open the print.html page
		$('a.PrintResults').click(function () {
			// get the searchId from the vitalSite object on the page and use that for the search
			if (("SearchData" in VitalSite) && VitalSite.SearchData != null && VitalSite.SearchData.SearchInfo != null) {
				var SearchTerms = {
					'SearchId' : VitalSite.SearchData.SearchInfo.SearchId
				}
				$.cookie('SearchId', JSON.stringify(SearchTerms), { expires: 7, path: '/' });
			}
			newwindow = window.open($(this).attr('href'), 'name', 'height='+ options.windowHeight +',width='+ options.windowWidth +',scrollbars=' + options.windowScrollbar);
			if (window.focus) { newwindow.focus(); }
			return false;
		});
		
		// check to see if the results are sorted from the url
		if($.url.param('sort')) {			
			$.cookie('Sort', $.url.param('sort'), { expires: 7, path: '/' });
		}
	};
}(jQuery));